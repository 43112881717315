<template>
<div id="travelmainpage">
<div class="leftzone">
    <div class="leftup">
   <a>inbox</a>
   <a>today</a>
   </div>
    <div>
    projects
    </div>
    <div @click="()=>{this.$router.push('/setting')}">setting</div>
     <div  @click="()=>{this.$router.push('/')}">logout</div>
</div>
<div class="rightzone">
    
    <div class="tasklist">
   <a>inbox</a>
   <TreeContainer id="treetaskzone" :showheader="false"></TreeContainer>
   </div>
</div>
</div>

</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import TreeContainer from './treecontainer.vue'
export default defineComponent({
  components: {
  TreeContainer
  },
  setup() {
    const state = reactive({
      checked1: true,
      checked2: false,
      checked3: false,
    });
    return {
      ...toRefs(state),
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#back{
  position: absolute;;
  left: 10%;
}
#travelmainpage{
    display: flex;
    flex-direction: row;
}

.leftup{
    display: flex;
    flex-direction: column;
}

.leftzone{
    display: flex;
    width: 20%;
    height: 100em;
    justify-content: left;
    flex-direction: column;
    background: lemonchiffon;
}


</style>
